import React from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { useTranslation } from 'react-i18next'

const Menu = ({ open, onCloseClick }) => {
  const { t } = useTranslation('layout')
  return (
    <section className={`menu ${open ? 'menu--show' : ''}`} onClick={onCloseClick}>
      <div className="menu__content">
        <Link to="/javiera" className="menu__content__link">{t('javiera')}</Link>
        <Link to="/experiencias" className="menu__content__link">{t('exp')}</Link>
        <HashLink to="/#contacto" className="menu__content__link">{t('contact')}</HashLink>
        <a href="https://www.instagram.com/cizzumbyjaviera/" target="_blank" className="menu__content__link menu__content__link--instagram icon-instagram"></a>
      </div>
    </section>
  )
}

export default Menu
