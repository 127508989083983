export default {
  home: {
    title: 'UNA FORMA ÚNICA DE CONOCER ARGENTINA',
    description: 'En Cizzum creamos experiencias exclusivas para viajeros afines',
    heroButton: 'Mi viaje',
    why: {
      title: '¿POR QUÉ VIAJAR CON NOSOTROS ES ÚNICO?'
    },
    proposals: {
      title: 'PROPUESTAS PERSONALIZADAS',
      description: 'Armamos itinerarios boutique. Escuchamos lo que el cliente quiere y nuestro objetivo es saber cuál es la mejor forma de viajar para cada uno.'
    },
    experiences: {
      title: 'EXPERIENCIA EN EL PAÍS',
      description: 'Hace 10 años Javiera fundó Cizzum y desde entonces organizamos experiencias únicas con un apasionado conocimiento de distintas opciones naturales, culturales y gastronómicas.'
    },
    taste: {
      title: 'GUSTO Y EXCELENCIA',
      description: 'El detalle nos diferencia. Acompañamos todo el proceso, desde la inspiración para comenzar el viaje, hasta la planificación de cada propuesta. Nos aseguramos que la experiencia cumpla la expectativa de nuestros clientes.'
    },
    experience: {
      line1: 'En Cizzum creemos que cada experiencia debe ser pensada a medida.',
      line2: 'Existe una mejor manera de viajar.'
    },
    clients: {
      title: 'MENSAJES DE CLIENTES',
      slider: {
        '1': {
          quote: '“El trabajo en el detalle y el toque personal de Javiera fueron la clave para que sea un evento memorable y único, recordado por todos después de tantos años”',
          author: 'Gustavo D’Alessandro, YPO, Wine & Life Argentina Experience 2010, Mendoza'
        },
        '2': {
          quote: '“Hemos vivido una experiencia única, con un cuidado absoluto al más mínimo detalle, visitando lugares increíbles y con una atención personalizada en toda momento. Como anfitriones hemos superado las expectativas de todos los miembros del grupo Crown, no paro de recibir felicitaciones por esta experiencia. Un lujo.”',
          author: 'Mariano Zegarelli Host, Crown Argentina 2019'
        },
        '3': {
          quote: '“Todos los estados emocionales en una experiencia única para nosotros. Javi logro entender y crear un finde inolvidable para celebrar el amor entre amigos y familia. En cada detalle, gesto y actividad sentí una dedicación y entrega hermosa para mi. Es la mejor partner del mundo!”',
          author: 'Patricia Pomies, Soromies Wedding Experience, Cafayate'
        },
        '4': {
          quote: '“Javiera organizó varios viajes para ejecutivos extranjeros a la perfección creando experiencias inolvidables. Calidad, atención a los detalles y experiencias excepcionales”',
          author: 'Claudio Muruzabal, YPO Couples, Patagonia & Buenos Aires, 2018'
        },
        '5': {
          quote: '“Tuvimos la suerte de estar en una impresionante gira por Buenos Aires, El Calafate y Mendoza. Fue una experiencia fantástica y, sin duda, la más organizada y agradable en la que hemos estado. Los eventos, el alojamiento y la comida fueron todos de la más alta calidad y mostraron lo maravilloso que es Argentina”',
          author: 'Jim and Karyl Robertson, New Zealand'
        }
      }
    },
    contact: {
      title: 'CONTACTANOS PARA SABER MÁS.',
      description: 'Planificamos para grupos que buscan una experiencia hecha a medida.',
      namePlaceholder: 'Nombre',
      emailPlaceholder: 'Email',
      textareaPlaceholder: 'Contanos qué estás pensando y qué tipo de viaje te imaginás.',
      button: 'Enviar mensaje'
    }
  },
  javiera: {
    hero: 'JAVIERA ALVAREZ ECHAGÜE',
    title: 'VIAJERA, EMPRENDEDORA, PROFESIONAL, ARGENTINA Y ANFITRIONA',
    p1: 'Durante toda mi carrera me dediqué a ofrecer servicios de excelencia. Guiada por mi pasión por viajar y todo lo que este mágico país ofrece, hoy continuo lo que empecé hace 10 años: Crear experiencias únicas con Cizzum.',
    p2: 'Con propuestas integrales y personalizadas interpretando las expectativas de cada cliente, mi misión es brindar una experiencia inolvidable junto a referentes de la gastronomía y el entretenimiento.',
    quote: '“Compartir cada rincón oculto de los lugares que encuentro, armar el menú perfecto, buscar el vino que maride ese menú, combinarlo con deporte, diversión y relax forman parte de mi pasión y misión para armar la receta perfecta de viaje.”',
    cta: 'CONTACTANOS PARA SABER MÁS',
    ctaStrong: 'SOBRE CIZZUM'

  },
  exp: {
    main: '"El disfrute comienza en la primera comunicación con el cliente, cuando empieza la inspiración. Escucho atentamente sus necesidades, sus expectativas, lo que sueña para su viaje y empiezo a armar el rompecabezas en mi mente para hacerlo realidad. Cuando lo vivido fue único y memorable, ese es el momento en que con felicidad siento que mi tarea está cumplida."',
    block1: 'Recordamos cómo nos sentimos durante y después de cada viaje, porque viajar es una experiencia transformadora.',
    block2: 'Nuestra misión es que cada persona pueda viajar como soñó hacerlo.',
    block3: 'Convertir un lugar increíble en un momento inolvidable.',
  },
  layout: {
    javiera: 'JAVIERA',
    exp: 'EXPERIENCIA',
    contact: 'CONTACTO'
  }
}