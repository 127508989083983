import React from 'react'

const Module = ({ highlight, title, description, image, right, color }) => {
  const position = right ? 'module module--right' : 'module'
  const titleColor = color ? 'module__data__title module__data__title--white' : 'module__data__title'

  if(highlight) {
    return (
      <section className={position}>
        <div className="module__data">
          <p className="module__data__highlight">{highlight}</p>
        </div>
        <div className="module__image"><img src={image} alt={highlight} /></div>
      </section>
    )
  } else {
    return (
      <section className={position}>
        <div className="module__data">
          {title && <h3 className={titleColor}>{title}</h3> }
          {description && <p className="module__data__description">{description}</p> }
        </div>
        <div className="module__image"><img src={image} alt={title} /></div>
      </section>
    )
  }
}

export default Module