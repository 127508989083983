import React, { useState } from 'react'
import Slider from 'react-slick'
import { HashLink } from 'react-router-hash-link'

import Layout from '../components/Layout'
import Module from '../components/Module'
import { useTranslation } from 'react-i18next'
import QuoteItem from '../components/QuoteItem'

const Home = () => {
  const { t } = useTranslation('home')
  const [process, setProcess] = useState(false)
  const [result, setResult] = useState(null)

  const contact = async () => {
    setProcess(true)
    const data = {
      name: document.getElementById('name').value,
      email: document.getElementById('email').value,
      content: document.getElementById('content').value
    }
    try {
      const r = await fetch('https://mailer-proxy-prod.herokuapp.com/cizzum', {
        method: 'POST',
        body: JSON.stringify(data),
        headers:{
          'Content-Type': 'application/json'
        }
      })
      setResult({ error: false, message: 'Mensaje Enviado!'})
    } catch(err) {
      setResult({ error: true, message: 'Algo salio mal, intentalo nuevamente!'})
    }
  }

  return (
    <Layout>
      <section className="herospace">
        <div className="herospace__content">
          <h2 className="herospace__content__title">{t('title')}</h2>
          <p className="herospace__content__description">{t('description')}</p>
          <div className="herospace__content__btn">
            <HashLink to="#contacto" className="herospace__content__btn__link">{t('heroButton')}</HashLink>
          </div>
        </div>
      </section>
      <Module highlight={t('why.title')} image="/images/servicio1.jpg" />
      <Module title={t('proposals.title')} description={t('proposals.description')} image="/images/servicio2.jpg" right="true" />
      <Module title={t('experiences.title')} description={t('experiences.description')} image="/images/servicio4.jpg" />
      <Module title={t('taste.title')} description={t('taste.description')} image="/images/servicio5.jpg" right="true" />

      <section className="experience">
        <p className="experience__content">{t('experience.line1')}</p>
        <p className="experience__content">{t('experience.line2')}</p>
      </section>
      <section className="clients">
        <div className="clients__data">
          <p className="clients__data__title">{t('clients.title')}</p>
        </div>
        <Slider
          dots={false}
          className={'clients__slider'}
          infinite
          autoplay
          autoplaySpeed={5000}
          pauseOnHover={false}
          speed={400}
          slidesToShow={1}
          slidesToScroll={1}
          arrows={true}>
          <QuoteItem 
            quote={t('clients.slider.1.quote')}
            author={t('clients.slider.1.author')}
            image={'/images/testimonio1.jpg'} />
          <QuoteItem 
            quote={t('clients.slider.2.quote')}
            author={t('clients.slider.2.author')}
            image={'/images/testimonio2.jpg'} />
          <QuoteItem 
            quote={t('clients.slider.3.quote')}
            author={t('clients.slider.3.author')}
            image={'/images/testimonio3.jpg'} />
          <QuoteItem 
            quote={t('clients.slider.4.quote')}
            author={t('clients.slider.4.author')}
            image={'/images/testimonio4.jpg'} />
          <QuoteItem 
            quote={t('clients.slider.5.quote')}
            author={t('clients.slider.5.author')}
            image={'/images/testimonio5.jpg'} />
        </Slider>
      </section>
      <section className="contact" id="contacto">
        <div className="contact__content">
          {result ? (
            <div className={`contact__content__message ${result.error ? 'error' : 'success'}`}>
              <p>{result.message}</p>
            </div>
          ) : 
          (
            <>
              <p className="contact__content__description">{t('contact.description')}</p>
              <h3 className="contact__content__title">{t('contact.title')}</h3>
              <div className="contact__content__form">
                <div className="contact__content__form__sep contact__content__form__sep--1">
                  <div className="contact__content__form__sep__input icon-name"><input type="text" id="name" placeholder={t('contact.namePlaceholder')} /></div>
                </div>
                <div className="contact__content__form__sep contact__content__form__sep--2">
                  <div className="contact__content__form__sep__input icon-email"><input type="text" id="email" placeholder={t('contact.emailPlaceholder')} /></div>
                </div>
                <div className="contact__content__form__sep contact__content__form__sep--3">
                  <div className="contact__content__form__sep__textarea"><textarea rows="4" id="content" placeholder={t('contact.textareaPlaceholder')}></textarea></div>
                </div>
                <div className="contact__content__form__btn">
                  <button onClick={contact} disabled={process}>{t('contact.button')}</button>
                </div>
              </div>
            </>
          )}
        </div>
      </section>

    </Layout>
  )
}

export default Home
