import React from 'react'
import { useTranslation } from 'react-i18next'

import Layout from '../components/Layout'
import Module from '../components/Module'

const Experiences = () => {
  const { t } = useTranslation('exp')
  return (
    <Layout>
      <section className="video">
        <video autoplay="autoplay" muted="muted" loop="loop" controls controlsList="nodownload">
          <source src="/video/cizzum.mp4" type="video/mp4" />
        </video>
      </section>
      <section className="cite">
        <p className="cite__text">{t('main')}</p>
      </section>
      <Module title={t('block1')} image="/images/portfolio7.jpg" color="true" />
      <Module title={t('block2')} image="/images/portfolio10.jpg" color="true" right="true" />
      <Module title={t('block3')} image="/images/portfolio3.jpg" color="true" />
      <section className="gallery">
        <div className="gallery__image"><img src="/images/portfolio11.jpg" /></div>
        <div className="gallery__image"><img src="/images/portfolio12.jpg" /></div>
        <div className="gallery__image"><img src="/images/portfolio13.jpg" /></div>
        <div className="gallery__image"><img src="/images/portfolio14.jpg" /></div>
        <div className="gallery__image"><img src="/images/portfolio15.jpg" /></div>
        <div className="gallery__image"><img src="/images/portfolio16.jpg" /></div>
      </section>
    </Layout>
  )
}

export default Experiences
