import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import './i18n'
import About from './views/About'
import Home from './views/Home'
import Experiences from './views/Experiences'

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/javiera" component={About} />
        <Route path="/experiencias" component={Experiences} />
        <Route component={Home} />
      </Switch>
    </BrowserRouter>
  )
}

export default App
