import React, { useState } from 'react'
import Menu from './Menu'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const Layout = ({children}) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const { i18n } = useTranslation()
  return (
    <>
      <header className="hd">
        <div className="hd__content">
          <a className="hd__content__hamb" onClick={() => setMenuOpen(!menuOpen)}>
            <span />
          </a>
          <Link to="/" className="hd__content__lg"><img src="/images/logo-head.png" alt="cizzum by javiera" /></Link>
          <div className="hd__content__lang">
            <a href="#" onClick={() => i18n.changeLanguage('es')}>ES</a>
            <a href="#" onClick={() => i18n.changeLanguage('en')}>EN</a>
          </div>
          <a href="https://www.instagram.com/cizzumbyjaviera/" target="_blank" className="hd__content__instagram icon-instagram"></a>
        </div>
      </header>
      {children}
      <footer className="ft">
        <div className="ft__content">
          <Link to="/" className="ft__content__lg"><img src="/images/logo-ft.png" alt="cizzum by javiera" /></Link>
        </div>
      </footer>

      <Menu open={menuOpen} onCloseClick={() => setMenuOpen(false)}/>
    </>
  )
}

export default Layout