export default {
  home: {
    title: 'A TAILOR-MADE WAY TO KNOW ARGENTINA',
    description: 'At Cizzum we create exclusive experiences for like-minded travelers',
    heroButton: 'My Trip',
    why: {
      title: 'WHY TRAVEL WITH US?'
    },
    proposals: {
      title: 'CUSTOMIZED PROPOSALS',
      description: 'Our goal is to create a boutique itinerary designed for each traveler to enjoy.'
    },
    experiences: {
      title: ' EXPERT EXPERIENCE PLANNING',
      description: 'Javiera founded Cizzum ten years ago and since then we have been planning unique experiences in Argentina and Uruguay.'
    },
    taste: {
      title: 'TASTE AND EXCELLENCE',
      description: 'We are in the details. We take part in every stage of the process and we ensure that every experience fulfills its purpose.'
    },
    experience: {
      line1: 'We believe our customers come first and that each experience must be tailored to them.',
      line2: 'There is a better way to travel in Argentina.'
    },
    clients: {
      title: 'MEMBER STORIES',
      slider: {
        '1': {
          quote: '“The work in detail and personal touch of Javiera were key to make a memorable and unique event, remembered by all everyone after so many years.”',
          author: 'Gustavo D’Alessandro, YPO, Wine & Life Argentina Experience 2010, Mendoza'
        },
        '2': {
          quote: '“We had a unique experience, with absolute care to the smallest details, visiting incredible places and with a personalized attention at all times. As a host myself, this exceeded the expectations of all members of the Crown Group. I am still congratulated for this experience. It was a luxury.”',
          author: 'Mariano Zegarelli Host, Crown Argentina 2019'
        },
        '3': {
          quote: '“We’ve had all emotional states in a unique experience for us. Javiera managed to understand and create an unforgettable weekend to celebrate love between friends and family. In every detail I felt her dedication.”',
          author: 'Patricia Pomies, Soromies Wedding Experience, Cafayate'
        },
        '4': {
          quote: '“Javiera organized several trips for foreign executives to perfection. She created unforgettable experiences with quality and exceptional attention to details.”',
          author: 'Claudio Muruzabal, YPO Couples, Patagonia & Buenos Aires, 2018'
        },
        '5': {
          quote: '“We were lucky enough to be on a stunning tour of Buenos Aires, El Calafate and Mendoza. It was a fantastic experience and without doubt, the most well organized and enjoyable tour we have been on. The events, accommodation and food were all of the highest standard and showcased what a wonderful country Argentina is.”',
          author: 'Jim and Karyl Robertson, New Zealand'
        }
      }
    },
    contact: {
      title: 'CONTACT US TO KNOW MORE ABOUT CIZZUM',
      description: 'We plan for groups looking for an experience tailored to their interests.',
      namePlaceholder: 'Name',
      emailPlaceholder: 'Email',
      textareaPlaceholder: 'Tell us what kind of trip you imagine.',
      button: 'Send'
    }
  },
  javiera: {
    hero: 'JAVIERA ALVAREZ ECHAGÜE',
    title: 'TRAVELER, ENTREPRENEUR, PROFESSIONAL AND HOSTESS',
    p1: 'Ten years ago, Javiera began planning trips in Argentina to help people experience the country through her eyes. Since then, she has been dedicated to explore the characteristic pleasures Argentina has to offer.',
    p2: 'Throughout her career, Javiera has been dedicated to provide excellence in every challenge she encounters. Passionate for travel and everything her country has to offer, she dedicates to create unique experiences tailor-made for her customers to enjoy the best activities, locations, gastronomy and entertainment in comprehensive and personalized proposals.',
    quote: '“To share every hidden place that I find, to build a perfect menu, to find the wine to marinate that menu, to combine sports, fun and relaxation are my passion and the cornerstone to put together the perfect recipe for a trip.”',
    cta: 'CONTACT US TO KNOW MORE',
    ctaStrong: 'ABOUT CIZZUM'

  },
  exp: {
    main: '“The enjoyment begins in the first communication with the traveler, when the idea begins. I listen carefully to the needs, expectations and dreams for your experience and begin to put the puzzle together to make it happen. If the experience was unique and memorable, then I can feel happy that my task has been accomplished.”',
    block1: 'We remember how we feel during and after each trip because traveling is a transformative experience.',
    block2: 'Our mission is that each person can travel as they dreamt.',
    block3: 'Turn an incredible place into an unforgettable moment.',
  },
  layout: {
    javiera: 'JAVIERA',
    exp: 'EXPERIENCE',
    contact: 'CONTACT'
  }
}