import React from 'react'
import { HashLink } from 'react-router-hash-link'

import Layout from '../components/Layout'
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation('javiera')
  return (
    <Layout>
      <section className="about-herospace">
        <h2 className="about-herospace__title">{t('hero')}</h2>
      </section>

      <section className="about-content">
        <h3 className="about-content__title">{t('title')}</h3>
        <p className="about-content__description">{t('p1')}</p>
        <p className="about-content__description">{t('p2')}</p>
        <div className="about-content__javiera"><img src="/images/avatar.jpg" /></div>
        <span className="about-content__cite">{t('quote')}</span>
        <div className="about-content__btn">
          <HashLink to="/#contacto" className="about-content__btn__link">
            {t('cta')} <span>{t('ctaStrong')}</span>
          </HashLink>
        </div>
      </section>
    </Layout>
  )
}

export default About
