import React from 'react'

const QuoteItem = ({ quote, author, image }) => {
  return (
    <div className="clients__slider__item" style={{backgroundImage: `url('${image}')`}}>
      <h4 className="clients__slider__item__title">{quote}</h4>
      <p className="clients__slider__item__description">{author}</p>
    </div>
  )
}

export default QuoteItem
